import { LocalizedDate } from 'components/Basic/Date/LocalizedDate';
import { ExtendedNextLink } from 'components/Basic/ExtendedNextLink/ExtendedNextLink';
import { ArticleMetadata } from 'components/Basic/Head/ArticleMetadata/ArticleMetadata';
import { BlogArticleMetadata } from 'components/Basic/Head/BlogArticleMetadata/BlogArticleMetadata';
import GrapeJsParser from 'components/Helpers/GrapeJsParser';
import Webline from 'components/Layout/Webline/Webline';
import { useDomainConfig } from 'hooks/useDomainConfig';
import { useRouter } from 'next/router';
import React, { Fragment, memo, useEffect } from 'react';
import { ArticleDetailType } from 'types/article';
import { BlogArticleDetailType } from 'types/blogArticle';

type ArticleDetailProps = {
    article: BlogArticleDetailType | ArticleDetailType;
};

const TEST_IDENTIFIER = 'pages-blogarticle-';

export const blogArticleTextContentTwClass = 'flex flex-col w-full max-w-[800px] mx-auto mb-12';
export const blogArticleTitleTwClass = 'break-words mb-6 block text-[45px] font-bold leading-12 text-primary';

const isBlogArticle = (article: BlogArticleDetailType | ArticleDetailType): article is BlogArticleDetailType => {
    return 'blogCategories' in article;
};

const ArticleDetail: FC<ArticleDetailProps> = ({ article }) => {
    const { url } = useDomainConfig();
    const router = useRouter();

    useEffect(() => {
        const { hash } = new URL(router.asPath, url);

        if (hash.split('?')[0]) {
            document.querySelector(hash.split('?')[0])?.scrollIntoView();
        }
    }, [router.asPath, url]);

    return (
        <Fragment key={article.uuid}>
            {isBlogArticle(article) ? <BlogArticleMetadata article={article} /> : <ArticleMetadata article={article} />}
            <Webline>
                <div className={blogArticleTextContentTwClass}>
                    <h1 className={blogArticleTitleTwClass} data-testid={TEST_IDENTIFIER + 'title'}>
                        {isBlogArticle(article) ? article.name : article.seoH1 ?? article.articleName}
                    </h1>
                    {isBlogArticle(article) && (
                        <div className="flex flex-wrap items-end">
                            {article.blogCategories.map((blogArticleCategory, index) => (
                                <Fragment key={blogArticleCategory.uuid}>
                                    {blogArticleCategory.parent !== null && (
                                        <ExtendedNextLink
                                            href={blogArticleCategory.link}
                                            type="blogCategory"
                                            className="mb-4 mr-2 text-small font-medium leading-none tracking-wider text-black no-underline xs:mr-5 lg:mr-10"
                                            data-testid={TEST_IDENTIFIER + 'category-' + index}
                                        >
                                            {blogArticleCategory.name}
                                        </ExtendedNextLink>
                                    )}
                                </Fragment>
                            ))}
                            <div
                                className="mb-4 text-tiny font-normal leading-none text-black"
                                data-testid={TEST_IDENTIFIER + 'date'}
                            >
                                <LocalizedDate date={article.publishDate} />
                            </div>
                        </div>
                    )}
                    {article.text !== null && (
                        <GrapeJsParser text={article.text} uuid={article.uuid} gtmListName="article products" />
                    )}
                </div>
            </Webline>
        </Fragment>
    );
};

export default memo(ArticleDetail);
