import Head from 'next/head';
import { ArticleDetailType } from 'types/article';

type ArticleMetadataProps = {
    article: ArticleDetailType;
};

export const ArticleMetadata: FC<ArticleMetadataProps> = ({ article }) => {
    return (
        <Head>
            <script
                type="application/ld+json"
                id="article-metadata"
                dangerouslySetInnerHTML={{
                    __html: JSON.stringify([
                        {
                            '@context': 'https://schema.org/',
                            '@type': 'Article',
                            headline: article.articleName,
                            datePublished: article.createdAt,
                        },
                    ]),
                }}
                key="article-metadata"
            />
        </Head>
    );
};
