import Head from 'next/head';
import { BlogArticleDetailType } from 'types/blogArticle';

type BlogArticleMetadataProps = {
    article: BlogArticleDetailType;
};

export const BlogArticleMetadata: FC<BlogArticleMetadataProps> = ({ article }) => {
    const metadataType = article.isRecipe ? 'Recipe' : 'NewsArticle';
    const description =
        article.isRecipe && article.perex
            ? article.perex
                  .replace(/<\/(p|h[1-6]|br)>/gi, '\n')
                  .replace(/<[^>]+>/g, '')
                  .replace(/&nbsp;/g, ' ')
                  .trim()
            : undefined;

    return (
        <Head>
            <script
                type="application/ld+json"
                id="blog-article-metadata"
                dangerouslySetInnerHTML={{
                    __html: JSON.stringify([
                        {
                            '@context': 'https://schema.org/',
                            '@type': metadataType,
                            headline: article.name,
                            image: article.blogArticlesGridImages,
                            datePublished: article.publishDate,
                            ...(description ? { description } : {}),
                        },
                    ]),
                }}
                key="article-metadata"
            />
        </Head>
    );
};
